import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { config } from './config';
import { dataUser, dataUserLicense, usersAU, newStudent } from '../models/findUser';
import { Grupo } from '../models/group';
import { Student } from '../models/student';


@Injectable({
    providedIn: 'root'
})
export class findUser {
    public url = config.url;

    constructor(public http: HttpClient) { }

    /**
     * Buscar usuarios
     */
    buscarUsuarios(token: string, datos: dataUser) {
        //Cabezeras
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${token}`);
        // Petición
        return this.http.post(this.url + 'admin/labtak/search', {
            email: datos.correo,
            name: datos.nombre,
            cct: datos.cct,
            source: datos.source,
            date_start: datos.fechaIn,
            date_end: datos.fechaFi
        }, { headers: headers });
    }
    buscarUsuariosLicencia(token: string, datos: dataUserLicense) {
        //Cabezeras
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${token}`);
        // Petición
        return this.http.post(this.url + 'admin/labtak/search', {
            email: datos.correo,
            name: datos.nombre,
            cct: datos.cct,
            user_rol_id: datos.user_rol_id,
            date_start: datos.fechaIn,
            date_end: datos.fechaFi
        }, { headers: headers });
    }
    buscarUsuariosAU(token: string, datos: usersAU) {
        // Cabezeras
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${token}`);
        // Petición
        return this.http.post(this.url + 'admin/labtak/search', {
            email: datos.correo,
            name: datos.nombre,
            cct: datos.cct,
            user_rol_id: datos.user_rol_id,
            date_start: datos.fechaIn,
            date_end: datos.fechaFi,
            licencia: datos.licencia,
            admin: datos.admin
        }, { headers: headers });
    }
    detailAU(token: string, id: number) {
        // Cabezeras
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${token}`);
        // Petición
        return this.http.get(this.url + `admin/labtak/teacher-detail/${id}`, { headers: headers });
    }

    addStudentTeka(token: string, data: newStudent): Observable<any> {
        // Cabezeras
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${token}`);
        const url = this.url + `tk-users/alumnos`;
        return this.http.post(url, data, { headers: headers });
    }

    /**
     * Traer los source de cat_source
     */
    getSources() {
        // Petición
        return this.http.get(this.url + '/admin/labtak/searchSources');
    }

    /**
     * Trae la infor de un usuario
     */
    getPersonalUser(token, idUser) {
        //Cabezeras
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${token}`);
        // Petición
        return this.http.get(this.url + `admin/labtak/teacher-detail/${idUser}`, { headers: headers });
    }
    /**
     * Actualizar la contraseña
     */
    setPassword(token: string, new_password: string, idUser: any) {
        //Cabezeras
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${token}`);
        // Petición
        return this.http.post(this.url + `admin/labtak/password/${idUser}`, {
            password: new_password
        }, { headers: headers });
    }

    /**
     * Borrar grupo de determinado maestro
     */
    deleteGroup(token: string, id_grupo: string) {
        // Cabezeras
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${token}`);
        // Petición
        return this.http.delete(this.url + `lt-users/grupos/${id_grupo}`, { headers: headers });
    }

    /**
     * crear grupo
     */
    createGroup(token: string, grupoService: Grupo, id: string) {
        //Cabezeras
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${token}`);
        // Petición
        return this.http.post(this.url + `lt-users/grupos`,
            {
                grado: grupoService.grado,
                grupo: grupoService.grupo,
                color: grupoService.color,
                letra: grupoService.letra,
                lt_user: id
            }
            , { headers: headers });
    }

    /**
     * Crear un alumno
     */
    createStudent(token: string, alumno: Student, id_teacher:string, _cct_id:string) {
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${token}`);
        // Petición
        return this.http.post(this.url + `lt-users/alumnos`,
            {
                username: alumno.username,
                fullname: alumno.fullname,
                password: alumno.password,
                grado: alumno.gradoS,
                grupo: alumno.grupoS,
                user_id: id_teacher,
                cct_id: _cct_id
            }
            , { headers: headers });
    }
}


