export class Licencia {
  public codigo: string;
  public licencia: string = '';
  public name_source: string = '';
  public fecha_inicio: string = '';
  public fecha_expiracion: string = '';
  public numero_usuarios: number = null;
  public pagado: number = 0;
  public cantidad: string = '';
  public tipo_pago: string = '';
  public membresia : number = 1;
  public id_secciones: number = null;
  public status : number = 1;
  public peticion: string = '0';
  public secciones: Secciones;
  public datos: DatosUsuario;
  public datos_facturacion: Facturacion;
  public capacitacion: Capacitacion;
}

export class DatosUsuario {
  public organizacion	: string  = '';
  public representante: string = '';
  public correo: string = '';
  public telefono: string = '';
  public nombre_vendedor: string = '';
  public id_sys_user: number;
  public facturacion: number = 0;
  public cct: number = 0;
}

export class Facturacion {
  public razon_social: string = '';
  public rfc: string = '';
  public calle: string = '';
  public no_interior: number;
  public no_exterior: number;
  public cp: string = '';
  public estado: string = '';
  public municipio: string = '';
  public colonia: string = '';
  public localidad : string = '';
  public CFDI : string = '';
  public email : string = '';
}

export class Secciones {
  public fichas: number;
  public buscador: number;
  public planeacion: string = '';
  public modulos_ttt: number;
  public modulos_hab: number;
  public planeaciones_ses: string = '';
  public reportak: number;
  public grupos: number;
  public blog: number;
  public foro: string = '';
  public webinars : string = '';
  public moocs : string = '';
}

export class Capacitacion {
  public numero	: number;
  public tipo_sesion: string = '';
  public nombre_capacitador: string = '';
  public condiciones: string = '';
  public fechas: Array<string>;
}

export class LicenciaEdit {
  public id_license: number;
  public id_capacitaciones: number;
  public id_datos: number;
  public codigo: string;
  public licencia: string = '';
  public name_source: string = '';
  public fecha_inicio: string = '';
  public fecha_expiracion: string = '';
  public numero_usuarios: number = null;
  public pagado: number = 0;
  public cantidad: string = '';
  public tipo_pago: string = '';
  public membresia : number = 1;
  public renovacion : number;
  public id_secciones: number = null;
  public status : number = 1;
  public peticion: string = '1';
  public secciones: Secciones;
  public datos: DatosUsuario;
  public datos_facturacion: Facturacion;
  public capacitacion: Capacitacion;
  public ultima_renovacion: UltimaRenovacion;
}

export class UltimaRenovacion {
  public id: number;
  public id_licencia: number;
  public fecha_inicio: string = '';
  public fecha_expiracion: string = '';
  public cantidad: string = '';
  public tipo_pago: string = '';
}

export class Renovar {
  public id_license: number;
  public fecha_inicio: string = '';
  public fecha_expiracion: string = '';
  public pagado: number = 0;
  public cantidad: string = '';
  public tipo_pago: string = '';
  public status : number = 1;
  public peticion: string = '2';
}

export class SchoolCCT {
  public estado : number;
  public municipio: string = '';
}