import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PagesRoutingModule } from './pages-routing.module';

import { PagesComponent } from './pages.component';
import { ProgressComponent } from './progress/progress.component';
import { DashboardComponent } from './dashboard/dashboard.component';

import { SharedModule } from '../shared/shared.module';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { LicenciasComponent } from './licencias/licencias.component';
import { JuegosComponent } from './juegos/juegos.component';
import { CardGameComponent } from './card-game/card-game.component';
import { JuegoDetalleComponent } from './juego-detalle/juego-detalle.component';
import { FormGameComponent } from './form-game/form-game.component';
import { ListaUsersComponent } from './lista-users/lista-users.component';
import { UserCreateComponent } from './user-create/user-create.component';
import { UserUpdateComponent } from './user-update/user-update.component';
import { KeysPipe } from './pipes/keys.pipe';
import { SanitizeHtmlPipe } from './pipes/sanitizeHtml.pipe';
import { NuevoJuegoComponent } from './nuevo-juego/nuevo-juego.component';
import { GameDescriptionComponent } from './game-description/game-description.component';
import { UsuariosDetalleComponent } from './usuarios-detalle/usuarios-detalle.component';

// ckeditor
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { GameProgramasComponent } from './game-programas/game-programas.component';
import { GameRecursosComponent } from './game-recursos/game-recursos.component';
import { AbilitiesComponent } from './abilities/abilities.component';
import { RutasDidacticasComponent } from './rutas-didacticas/rutas-didacticas.component';
import { RecursosExternosComponent } from './recursos-externos/recursos-externos.component';
import { CreateRecursoComponent } from './create-recurso/create-recurso.component';
import { UpdateRecursoComponent } from './update-recurso/update-recurso.component';
import { LicenciasLtComponent } from './licencias-lt/licencias-lt.component';
import { UsersAuComponent } from './users-au/users-au.component';
import { AuDetailComponent } from './au-detail/au-detail.component';
import { AuStudentsComponent } from './au-students/au-students.component';
import { AuAddStudentComponent } from './au-add-student/au-add-student.component';
import { LicenciasTTtekaComponent } from './licencias-ttteka/licencias-ttteka.component';
import { LicenciasTttekaDetalleComponent } from './licencias-ttteka-detalle/licencias-ttteka-detalle.component';
import { MembresiaComponent } from './membresia/membresia.component';
import { MembresiaEditarComponent } from './membresia-editar/membresia-editar.component';
import { SeccionesLtComponent } from './secciones-lt/secciones-lt.component';
import { MembresiaRenovarComponent } from './membresia-renovar/membresia-renovar.component';

import { blogAutorComponent } from './blog-autor/blogAutor.component';
import { blogCrearArticuloComponent } from './blog-crearArticulo/blogCrearArticulo.component';
import { blogEditarArticuloComponent } from './blog-editarArticulo/blogEditarArticulo.component';
import { foroComponent } from './foro/foro.component';
import { capacitacionCrearWebinar } from './capacitacion-crearWebinar/capacitacionCrearWebinar.component';
import { capacitacionGestorWebinar } from './capacitacion-gestorWebinar/capacitacionGestorWebinar.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSlideToggleModule } from '@angular/material/slide-toggle'; //material
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule} from '@angular/material';
import { MatInputModule } from '@angular/material/input';

import { AngularEditorModule } from '@kolkov/angular-editor';
import { LibroNuevoComponent } from './libro-nuevo/libro-nuevo.component';
import { LibrosComponent } from './libros/libros.component';
import { LinkLibrosComponent } from './link-libros/link-libros.component';
import { UpdateLibroComponent } from './update-libro/update-libro.component';
import { MoocCrearComponent } from './mooc-crear/mooc-crear.component';
import { MoocGestionComponent } from './mooc-gestion/mooc-gestion.component';
import { MoocEvaluarComponent } from './mooc-evaluar/mooc-evaluar.component';

@NgModule({
  declarations: [
    PagesComponent,
    DashboardComponent,
    ProgressComponent,
    UsuariosComponent,
    LicenciasComponent,
    JuegosComponent,
    CardGameComponent,
    JuegoDetalleComponent,
    FormGameComponent,
    ListaUsersComponent,
    UserCreateComponent,
    UserUpdateComponent,
    KeysPipe,
    SanitizeHtmlPipe,

    NuevoJuegoComponent,
    GameDescriptionComponent,
    GameProgramasComponent,
    GameRecursosComponent,
    AbilitiesComponent,
    RutasDidacticasComponent,
    UsuariosDetalleComponent,
    RecursosExternosComponent,
 
    CreateRecursoComponent,
    UpdateRecursoComponent,
    LicenciasLtComponent,
    UsersAuComponent,
    AuDetailComponent,
    AuStudentsComponent,
    AuAddStudentComponent,
    LicenciasTTtekaComponent,
    LicenciasTttekaDetalleComponent,
    MembresiaComponent,
    MembresiaEditarComponent,
    SeccionesLtComponent,
    MembresiaRenovarComponent,

    blogAutorComponent,
    blogCrearArticuloComponent,
    blogEditarArticuloComponent,
    foroComponent,
    capacitacionCrearWebinar,
    capacitacionGestorWebinar,
    LibroNuevoComponent,
    LibrosComponent,
    LinkLibrosComponent,
    UpdateLibroComponent,
    MoocCrearComponent,
    MoocGestionComponent,
    MoocEvaluarComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    PagesRoutingModule,
    FormsModule,
    CKEditorModule,
    BrowserAnimationsModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    AngularEditorModule
  ]
})
export class PagesModule { }
