import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CapacitacionService } from 'src/app/services/capacitacion.service';
import Swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'app-mooc-evaluar',
  templateUrl: './mooc-evaluar.component.html',
  styleUrls: ['./mooc-evaluar.component.css']
})
export class MoocEvaluarComponent implements OnInit {
  
  private token: string = localStorage.getItem('token');
  public id = 0;
  public modulos;
  public evaluacion;
  public modev = [];

  constructor(private capacitacionService: CapacitacionService, private activatedRoute: ActivatedRoute, private router: Router) {  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.id = params.id;
    });

    this.capacitacionService.getMooc(this.id, this.token).subscribe((response: any) => {
      this.modulos = response.mooc.modulos;
      console.log(response)
    }); 

    this.capacitacionService.getEvaluacion(this.id, this.token).subscribe((response: any) => {
      console.log(response);
      this.evaluacion = response.info_evaluacion;
    });
  }
  
  evaluar(ie, j){
    this.modev = [];
    ie.num = j;
    this.modev = ie;
    $(".modal-evaluar").modal("show");
  }

  enviarEval(ie){
    console.log(this.modev)
    this.capacitacionService.postEvaluacion(this.modev, this.token).subscribe((response: any) => {  
      Swal.fire("",response.message,"success");
      this.evaluacion.forEach((element, index) => {
        if(index == ie.num-1) element.calificacion = 1;
      });
    }, error => {
      Swal.fire("",error.message,"error");
    });
  }


}
