import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Capacitacion, DatosUsuario, Facturacion, Licencia, LicenciaEdit, Secciones } from 'src/app/models/licencias';
import { AdquisicionService } from 'src/app/services/adquisicion.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-membresia-editar',
  templateUrl: './membresia-editar.component.html',
  styleUrls: ['./membresia-editar.component.css']
})
export class MembresiaEditarComponent implements OnInit {

  token: string = localStorage.getItem('token');
  public licenciasAll = [] ;
  public mostrar = 0;
  
  public licencia = new LicenciaEdit();
  public usuario = new DatosUsuario();
  public factura = new Facturacion();
  public capacitacion = new Capacitacion();
  public secciones = new Secciones();

  Arr = Array;
  public numero_cap = 0;
  public fechas_capacitaciones = [];

  public id_licencia;
  public id_capacitaciones;
  public id_datos;
  public id_secciones;
  public id_facturacion;
  public correos;
  public expirado = 0;
  public renovaciones = [];

  public usuarios_inv = [];
  public usuarios_lic = [];
  
  fileToUpload: File = null;

  secciones_dis: Array<any> = [
    { nombre: 'Fichas de videojuegos TAKTAKTAK', id: 1, value: false, tipo: 1 },
    { nombre: 'Buscador de recursos', id: 1, value: false, tipo: 2 },
    { nombre: 'Módulos Labtak', id: 1, value: false, tipo: 3 },
    { nombre: 'Módulos Habilidades', id: 1, value: false, tipo: 12 },
    { nombre: 'ReporTAK', id: 1, value: false, tipo: 4 },
    { nombre: 'Grupos', id: 1, value: false, tipo: 5 },
    { nombre: 'Blog', id: 1, value: false, tipo: 6 },
  ];

  constructor(private adquisicionService: AdquisicionService) { 
    this.adquisicionService.getSecciones(this.token).subscribe((response: any) => {
      response.secciones.seccion_planeaciones.forEach(p => {
        this.secciones_dis.push({nombre: p.tipo_planeacion, id: p.id, value: false, tipo: 7 }); 
      });
      response.secciones.seccion_planeaciones_ses.forEach(ps => { 
        this.secciones_dis.push({nombre: ps.tipo_planeaciones_ses, id: ps.id, value: false, tipo: 8 }); 
      });
      response.secciones.seccion_foro.forEach(f => {
        this.secciones_dis.push({nombre: f.tipo_foro, id: f.id, value: false, tipo: 9 });
      });
      response.secciones.seccion_moocs.forEach(m => {
        this.secciones_dis.push({nombre: m.nombre, id: m.id, value: false, tipo: 10 }); 
      });
      response.secciones.seccion_webinars.forEach(w => { 
        this.secciones_dis.push({nombre: w.tipo_webinar, id: w.id, value: false, tipo: 11 }); 
      });
    }, error => {
      console.log(error.error.message);
    });
  }

  ngOnInit() {
    this.adquisicionService.getLicencias(this.token).subscribe((response: any) => {
      this.licenciasAll = response.licenses;
    }, error => {
      console.log(error.error.message);
    });
  }

  /* Licencias  */

  selectLicencia(id_licencia){
    this.mostrar = 1;
    this.adquisicionService.getLicencia(this.token, id_licencia).subscribe((response: any) => {
      console.log(response);
      this.licencia = response.license;
      this.usuario = this.licencia.datos;
      this.capacitacion = this.licencia.capacitacion;
      this.capacitacion.numero = response.license.capacitacion.num_sesiones;
      this.capacitacion.nombre_capacitador = response.license.capacitacion.capacitador;
      this.capacitacion.condiciones = response.license.capacitacion.condiciones_especiales;

      this.numero_cap = Number(this.capacitacion.numero);
      this.fechas_capacitaciones = this.capacitacion.fechas;

      this.licencia.id_license = response.license.id ;
      this.id_licencia = response.license.id ;
      this.id_capacitaciones = response.license.id_capacitaciones;
      this.id_datos = response.license.id_datos;
      this.id_secciones = response.license.id_secciones;
      this.id_facturacion = response.license.datos.id_facturacion;

      if(response.license.datos.id_facturacion!= null) this.factura = this.licencia.datos_facturacion;
  
      if(this.licencia.id_secciones>3) {
        this.licencia.id_secciones = 0;
        this.getSecc(response.license.secciones);
      }

      this.usuarios_inv = response.users.out;
      this.usuarios_lic = response.users.in;

      if(this.licencia.renovacion==1) this.renovaciones = response.license.renovaciones;
      console.log(this.renovaciones)

      this.verificarfecha();
      
    }, error => {
      console.log(error.error.message);
    });
  }

  regresar(){
    this.mostrar = 0;
    this.licencia = null;
    this.ngOnInit();
  }
  reg(val){
    this.regresar();
  }

  activar(event: any, numero) { 
    if (event.target.checked) {
      (numero == 1) ? this.licencia.pagado = 1 : this.usuario.facturacion = 1;
    } else {
      (numero == 1) ? this.licencia.pagado = 0 : this.usuario.facturacion = 0;
    }
  }
  sesiones:any = (e) => {
    this.numero_cap = Number(this.capacitacion.numero);
  }

  getSecc(secciones){
    if(secciones.fichas == 1) this.secciones_dis[0].value = true;
    if(secciones.buscador == 1) this.secciones_dis[1].value = true;
    if(secciones.modulos_ttt == 1) this.secciones_dis[2].value = true;
    if(secciones.modulos_hab == 1) this.secciones_dis[3].value = true;
    if(secciones.reportak == 1) this.secciones_dis[4].value = true;
    if(secciones.grupos == 1) this.secciones_dis[5].value = true;
    if(secciones.blog == 1) this.secciones_dis[6].value = true;
    let plan = secciones.planeacion.split(',');
    let planses = secciones.planeaciones_ses.split(',');
    let foro = secciones.foro.split(',');
    let moocs = secciones.moocs.split(',');
    let webinars = secciones.webinars.split(',');

    plan.forEach(p => {
      if(p!=0){
        this.secciones_dis.forEach(sd => {
          if(sd.tipo == 7){
            if(p == sd.id) sd.value=1;
          }
        });
      }
    });
    planses.forEach(p => {
      if(p!=0){
        this.secciones_dis.forEach(sd => {
          if(sd.tipo == 8){
            if(p == sd.id) sd.value=1;
          }
        });
      }
    });
    foro.forEach(p => {
      if(p!=0){
        this.secciones_dis.forEach(sd => {
          if(sd.tipo == 9){
            if(p == sd.id) sd.value=1;
          }
        });
      }
    });
    moocs.forEach(p => {
      if(p!=0){
        this.secciones_dis.forEach(sd => {
          if(sd.tipo == 10){
            if(p == sd.id) sd.value=1;
          }
        });
      }
    });
    webinars.forEach(p => {
      if(p!=0){
        this.secciones_dis.forEach(sd => {
          if(sd.tipo == 11){
            if(p == sd.id) sd.value=1;
          }
        });
      }
    });

  }

  asignaraSecciones() {
    this.secciones.planeacion='';
    this.secciones.planeaciones_ses='';
    this.secciones.foro='';
    this.secciones.moocs='';
    this.secciones.webinars='';

    this.secciones_dis.forEach(sec => {
      if(sec.tipo == 1) (sec.value) ? this.secciones.fichas = 1 : this.secciones.fichas = 0 ;
      else if(sec.tipo == 2) (sec.value) ? this.secciones.buscador = 1 : this.secciones.buscador = 0;
      else if(sec.tipo == 3) (sec.value) ? this.secciones.modulos_ttt = 1 : this.secciones.modulos_ttt = 0;
      else if(sec.tipo == 12) (sec.value) ? this.secciones.modulos_hab = 1 : this.secciones.modulos_hab = 0;
      else if(sec.tipo == 4) (sec.value) ? this.secciones.reportak = 1 : this.secciones.reportak = 0;
      else if(sec.tipo == 5) (sec.value) ? this.secciones.grupos = 1 : this.secciones.grupos = 0;
      else if(sec.tipo == 6) (sec.value) ? this.secciones.blog = 1 : this.secciones.blog = 0;
      else if(sec.tipo == 7) { 
        if(sec.value){
          (this.secciones.planeacion=='') ? this.secciones.planeacion = sec.id  : this.secciones.planeacion = this.secciones.planeacion + ',' + sec.id  ;
        }
      }
      else if(sec.tipo == 8) { 
        if(sec.value){
          (this.secciones.planeaciones_ses=='') ? this.secciones.planeaciones_ses = sec.id  : this.secciones.planeaciones_ses = this.secciones.planeaciones_ses + ',' + sec.id;
        }
      }
      else if(sec.tipo == 9) { 
        if(sec.value){
          (this.secciones.foro=='') ? this.secciones.foro = sec.id  : this.secciones.foro =  this.secciones.foro + ',' + sec.id;
        }
      }
      else if(sec.tipo == 10) { 
        if(sec.value){
          (this.secciones.moocs=='') ? this.secciones.moocs = sec.id  : this.secciones.moocs =  this.secciones.moocs + ',' + sec.id;
        }
      }
      else if(sec.tipo == 11) { 
        if(sec.value){
          (this.secciones.webinars=='') ? this.secciones.webinars = sec.id  : this.secciones.webinars =  this.secciones.webinars  + ',' + sec.id;
        }
      }
    });
    if(this.secciones.planeacion=='') this.secciones.planeacion = '0';
    if(this.secciones.planeaciones_ses=='') this.secciones.planeaciones_ses = '0';
    if(this.secciones.foro=='') this.secciones.foro = '0';
    if(this.secciones.moocs=='') this.secciones.moocs = '0';
    if(this.secciones.webinars=='') this.secciones.webinars = '0';
  }

  guardarCambios(form: NgForm) {
    Swal.showLoading();
    if(form.valid) {
      this.licencia.peticion='1';
      this.capacitacion.fechas = this.fechas_capacitaciones;
      if(this.id_secciones>3 && this.licencia.id_secciones>0){ // Si era personalizada y cambia a un paquete
        this.id_secciones = this.licencia.id_secciones;
      }
      else if(this.id_secciones<4 && this.licencia.id_secciones==0){ // Si era paquete y cambia a personalizada
        this.asignaraSecciones();
        this.licencia.id_secciones = 0; 
        this.licencia.secciones = this.secciones;
      }
      else if(this.licencia.id_secciones==0 && this.id_secciones>3){ //Si es personalizada y sigue personalizada
        this.asignaraSecciones();
        this.licencia.id_secciones=this.id_secciones; 
        this.licencia.secciones = this.secciones;
      }
      this.licencia.id_secciones = Number(this.licencia.id_secciones);

      this.licencia.datos = this.usuario;
      if(this.usuario.facturacion==1) this.licencia.datos_facturacion = this.factura;
      if(this.licencia.pagado==0) this.licencia.status = 0;
      if(this.licencia.pagado==1) this.licencia.status = 1;
      this.licencia.capacitacion = this.capacitacion;
      if(this.licencia.renovacion==1){
        let n = this.renovaciones.length;
        this.licencia.ultima_renovacion = this.renovaciones[n-1];
      }

      console.log(this.licencia);

      this.adquisicionService.editarLicencia(this.token, this.licencia).subscribe((response: any) => {
        
        Swal.close();
          Swal.fire({
            type: 'success',
            title: 'Licencia actualizada ',
            showConfirmButton: false,
            timer: 2000
          });
          this.mostrar=0;
        }, error => {
          
          console.log(error);
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error al editar la licencia:' + error.error.message
          });
        });
    }
    else{
      Swal.fire({
        type: 'error',
        title: '',
        text: 'No has llenado todos los campos.'
      });
    }
  }

  /* Invitaciones de usuarios  */

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }
  subirArchivo(){
    let correos = '';
    this.adquisicionService.uploadFile(this.fileToUpload, this.id_licencia).subscribe((response: any) => {
      console.log(response);
      if(response.list){
          correos = '\nLos correos que no se invitaron son: ';
          response.list.forEach((element, index) => {
            if(element!=null){
              if(index == 0) correos = correos + element;
              else correos = correos + ' , ' + element;
            }
          });
          Swal.fire({
            type: 'error',
            text: response.message + ' \n' + correos,
          });
        }
        else{
          Swal.fire({
            type: 'success',
            text: response.message,
            showConfirmButton: true,
          });
        }
      }, error => {
        console.log(error);
        correos = '\nLos correos que no se invitaron son: ';
        error.error.list.forEach((element, index) => {
          if(element!=null){
            if(index == 0) correos = correos + element;
            else correos = correos + ' , ' + element;
          }
        });
        Swal.fire({
          type: 'error',
          title: 'Error',
          text: error.error.message + ' \n' + correos,
        });
      });
  }

  guardarCorreos(){
    console.log(this.correos);
    let correosenviar = this.correos.split(',');
    console.log(correosenviar);
    this.adquisicionService.enviarInvitaciones(this.token, {emails: correosenviar, id_license: this.id_licencia }).subscribe((response: any) => {
      console.log(response);
       let correos = '';
        if(response.list){
          correos = '\nLos correos que no se invitaron son: ';
          response.list.forEach((element, index) => {
            if(index == 0) correos = correos + element;
            else correos = correos + ' , ' + element;
          });
        Swal.fire({
            type: 'error',
            text: response.message + ' \n' + correos,
          });
        }
        else{
          Swal.fire({
            type: 'success',
            text: response.message,
            showConfirmButton: true,
          });
        }
      }, error => {
        console.log(error);
        let correos = '\nLos correos que no se invitaron son: ';
        error.error.list.forEach((element, index) => {
          if(index == 0) correos = correos + element;
          else correos = correos + ' , ' + element;
        });
        Swal.fire({
          type: 'error',
          title: 'Error',
          text: error.error.message + ' \n' + correos,
        });
      });
  }

  /* Status de los usuarios  */
  cancelar_inv(id){
    Swal.fire({
        title: '',
        html: '<h3>¿Estas seguro de cancelar la invitación al usuario?',
        showCancelButton: true,
        confirmButtonColor: '#0098CE',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        cancelButtonText:'Cancelar'
      }).then((result) => {
        if (result.value) {
          this.adquisicionService.cancelarInvitacion(this.token, id, {status: 2}).subscribe((response: any) => {
              Swal.fire({
                type: 'success',
                title: 'Invitación del usuario cancelada ',
                showConfirmButton: false,
                timer: 2000
              });
            }, error => {
              Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: 'Ocurrio un error al cancelar la invitación'
              });
            });
        }
      });
  }
  cancelar_lic(id){
    Swal.fire({
        title: '',
        html: '<h3>¿Estas seguro de cancelar la licencia al usuario?',
        showCancelButton: true,
        confirmButtonColor: '#0098CE',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        cancelButtonText:'Cancelar'
      }).then((result) => {
        if (result.value) {
          this.adquisicionService.cancelarUsuario(this.token, id, {status: 0}).subscribe((response: any) => {
              Swal.fire({
                type: 'success',
                title: 'Licencia del usuario cancelada ',
                showConfirmButton: false,
                timer: 2000
              });
            }, error => {
              Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: 'Ocurrio un error al cancelar la licencia'
              });
            });
        }
      });
  }

  renovar(){
    this.mostrar=2;
  }

  verificarfecha(){
    let fechauno = new Date().toISOString().slice(0,10); 
    let fechados;
    if(this.licencia.renovacion == 0){
      fechados = new Date(this.licencia.fecha_expiracion).toISOString().slice(0,10);
    }
    else{
      let n = this.renovaciones.length;
      fechados = new Date(this.renovaciones[n-1].fecha_expiracion).toISOString().slice(0,10);
    }
    if(fechauno > fechados) this.expirado=1;
  }
}
